@import '../../styleResources/colors';

.shadowBox {
    padding: 1em;
    border-radius: 12px;
    box-shadow : 0px 5px 15px rgba(160, 160, 160, 0.58);
    background-color: #fff;
    
    &__title {
        padding-bottom: 10px;
        font-size: 20px;
    }

    &__loading {
        height: calc(100% - 30px);
    }
}

@media screen and (max-width: 440px){
    .shadowBox {
        border-radius: 0;
    }
}
